import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import currencyHelper from '../../../helpers/currencyHelper';

import { ViewTable } from '../../../components/layouts/viewTable';
import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { DonationPlatformFeeSectionRow } from './components/donationPlatformFeeSectionRow';
import { DonationEventOrganizerFeeSectionRow } from './components/donationEventOrganizerFeeSectionRow';
import { DonationFinancialsPlatformSectionRow } from './components/donationFinancialsPlatformSectionRow';
import { DonationStatusBadgeSectionRow } from './components/donationBadgeSectionRow';

import {
  donationI18Keys,
  donationSettings
} from './constants/donationSettings';
import config from '../../../constants';
import {
  APPLE_PAY_DONATION_WALLET_TYPE,
  GOOGLE_PAY_DONATION_WALLET_TYPE,
  OFFLINE_DONATION_TYPE
} from '../../campaign/constants';
import { DonationPlatformSilentFeeSectionRow } from './components/donationPlatformSilentFeeSectionRow';

const getWalletDonationType = (financials) => {
  const walletDonationType = get(financials, 'wallet');

  if (walletDonationType) {
    return walletDonationType === GOOGLE_PAY_DONATION_WALLET_TYPE
      ? 'Google Pay'
      : walletDonationType === APPLE_PAY_DONATION_WALLET_TYPE
      ? 'Apple Pay'
      : 'Other';
  }

  return 'None';
};

export const DonationPaymentDetails = (props) => {
  const { amount: donationAmount, financials, type, currency: donationCurrency, organization } = props.donation;

  const amount = props.displayOrgCurrency && get(financials, "exchangeRate")
    ? donationAmount * get(financials, "exchangeRate")
    : donationAmount;
  const currency = props.displayOrgCurrency ? get(organization, "currency") : donationCurrency;

  const typeOfDonation = type || 'N/A';
  const refunded = get(financials, 'refunded');

  const amountString = currencyHelper.formatCurrency(amount, currency);

  const donationPrimaryStatusBackgroundColor =
    financials &&
    donationSettings[typeOfDonation].getPrimaryStatusBackgroundColor(refunded);
  const donationSecondaryStatusBackgroundColor =
    financials &&
    donationSettings[typeOfDonation].getSecondaryStatusBackgroundColor();

  const donationPrimaryStatusValue =
    financials &&
    donationSettings[typeOfDonation].getPrimaryStatusValue(refunded);
  const donationSecondaryStatusValue =
    financials && donationSettings[typeOfDonation].getSecondaryStatusValue();

  const paymentPlatform = financials && financials.platform.capitalize();
  const paymentFeeTitle = I18n.t(
    `${donationI18Keys.donationPaymentDetailsI18Key}.payment-fee`,
    { paymentPlatform: paymentPlatform }
  );

  const feeDetails = get(financials, 'feeDetail');
  const fee = props.displayOrgCurrency
    ? get(feeDetails, 'fee') * get(financials, "exchangeRate")
    : get(feeDetails, 'fee');

  const feeDetailNet = props.displayOrgCurrency
    ? get(feeDetails, 'net') * get(financials, "exchangeRate")
    : get(feeDetails, 'net');
  const feeDetailNetString = currencyHelper.formatCurrency(feeDetailNet, currency);

  const paymentPlatformFee = props.displayOrgCurrency
    ? get(feeDetails, 'paymentPlatformFee') * get(financials, "exchangeRate")
    : get(feeDetails, 'paymentPlatformFee');
  const paymentPlatformFeeString = currencyHelper.formatCurrency(
    paymentPlatformFee,
    currency
  );

  const eventOrganizerFee = props.displayOrgCurrency
    ? get(feeDetails, 'eventOrganizerFee') * get(financials, "exchangeRate")
    : get(feeDetails, 'eventOrganizerFee');

  const transferArrivalEstimate = get(
    financials,
    'platformDetails.tranferArrivalEstimate'
  );
  const transferArrivalEstimateDate = transferArrivalEstimate
    ? moment(transferArrivalEstimate).format(
        config.INVERSION_FULL_DATE_TIME_PERIODS_FORMAT
      )
    : '';

  const isOffline = type === OFFLINE_DONATION_TYPE;

  const silentFee = props.displayOrgCurrency
    ? get(feeDetails, 'silentFee') * get(financials, "exchangeRate")
    : get(feeDetails, 'silentFee');

  const walletDonationType = getWalletDonationType(financials);
  const hasWallet = financials?.wallet && financials?.wallet !== 'none';

  const terminalKey = financials?.platformDetails?.terminalKey;
  const hasTerminalKey = !!terminalKey;

  const threeDsResult = financials?.threeDSecure?.result;

  return (
    <div className="donation-payment-delails">
      <ViewTable title="Payment Details">
        <DonationStatusBadgeSectionRow
          title={`${donationI18Keys.donationPaymentDetailsI18Key}.status`}
          primaryBackgroundColor={donationPrimaryStatusBackgroundColor}
          secondaryBackgroundColor={donationSecondaryStatusBackgroundColor}
          primaryValue={donationPrimaryStatusValue}
          secondaryValue={donationSecondaryStatusValue}
        />
        <ViewSectionRow
          title={`${donationI18Keys.donationPaymentDetailsI18Key}.total-paid`}
          data={amountString}
        />
        {feeDetails && fee !== null && fee !== 0 && fee > 0 && (
          <DonationPlatformFeeSectionRow
            title={`${donationI18Keys.donationPaymentDetailsI18Key}.platform-fee`}
            data={props.donation}
            displayOrgCurrency={props.displayOrgCurrency}
          />
        )}
        {silentFee !== null && silentFee !== 0 && silentFee > 0 && (
          <DonationPlatformSilentFeeSectionRow
            title={`${donationI18Keys.donationPaymentDetailsI18Key}.platform-fee`}
            data={props.donation}
            displayOrgCurrency={props.displayOrgCurrency}
          />
        )}
        {feeDetails &&
          paymentPlatformFee !== null &&
          paymentPlatformFee !== 0 && (
            <ViewSectionRow
              dynamicI18KeyTitle={paymentFeeTitle}
              data={paymentPlatformFeeString}
            />
          )}
        {feeDetails &&
          eventOrganizerFee !== null &&
          eventOrganizerFee !== 0 && (
            <DonationEventOrganizerFeeSectionRow
              title={`${donationI18Keys.donationPaymentDetailsI18Key}.event-organizer-fee`}
              data={props.donation}
              displayOrgCurrency={props.displayOrgCurrency}
            />
          )}
        {!isOffline && (
          <React.Fragment>
            {feeDetails && (
              <ViewSectionRow
                title={`${donationI18Keys.donationPaymentDetailsI18Key}.total-payout`}
                data={feeDetailNetString}
              />
            )}
            {hasWallet && (
              <ViewSectionRow
                title={`${donationI18Keys.donationPaymentDetailsI18Key}.payment-wallet`}
                data={walletDonationType}
              />
            )}
            {threeDsResult && (
              <ViewSectionRow
                title={`${donationI18Keys.donationPaymentDetailsI18Key}.three-d-secure`}
                data={threeDsResult}
              />
            )}
            <DonationFinancialsPlatformSectionRow
              title={`${donationI18Keys.donationPaymentDetailsI18Key}.gateway`}
              data={financials}
              paymentPlatforms={props.paymentPlatforms}
              isPaymentPlatformsLoaded={props.isOrganizationLoaded}
              getPaymentPlatforms={props.getOrganizationRecord}
              isSystemAdmin={props.isSystemAdmin}
            />
          </React.Fragment>
        )}
        <ViewSectionRow
          title={`${donationI18Keys.donationPaymentDetailsI18Key}.payout-status`}
          data={transferArrivalEstimateDate}
        />
        {hasTerminalKey && (
          <ViewSectionRow
            title={`${donationI18Keys.donationPaymentDetailsI18Key}.terminal-key`}
            data={terminalKey}
          />
        )}
      </ViewTable>
    </div>
  );
};
