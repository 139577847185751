import { TemplateOptions } from '../../../components/template/templateOptions';
import update from '../../../helpers/update';
import {
  individualAboutDefaultText,
  teamAboutDefaultText,
  individualAboutDefaultTextInMemoryCampaign,
  customUrlStyles,
  ticketPurchaseFundraisingStyles,
  mandatoryOptions,
  defaultTicketPurchaseFundraisingStyle,
  defaultCustomUrlStyle,
  collectionAboutDefaultText
} from '../constants';
import { customFieldCategory } from '../../../constants/formBuilder';
import { defaultWebTrackingElements } from '../../../helpers/webTrackingHelper';

export const campaignTypes = {
  default: 'default',
  giveNow: 'givenow',
  appeal: 'appeal',
  bespoke: 'bespoke',
  inMemory: 'inmemory',
  celebration: 'celebration',
  challenge: 'challenge',
  cycling: 'cycling',
  running: 'running',
  event: 'event',
  custom: 'custom'
};

const getDefaultModel = () => {
  let model = {};
  for (var propertyName in campaignContract) {
    const path = campaignContract[propertyName];
    model = update.set(model, path, null);
  }

  model = update.set(model, campaignContract.defaultAmounts, [
    { isDefault: true, amount: 30, description: '', image: '' },
    { isDefault: false, amount: 50, description: '', image: '' },
    { isDefault: false, amount: 75, description: '', image: '' },
    { isDefault: false, amount: 100, description: '', image: '' }
  ]);

  model = update.set(model, campaignContract.primaryColor, '#FF0000');
  model = update.set(model, campaignContract.secondaryColor, '#0000FF');
  // confetti
  model = update.set(model, campaignContract.specialEffectsConfettiEnabled, true);

  return model;
};

export const campaignContract = {
  type: 'type',
  organization: 'organizationId',
  name: 'name',
  targetAmount: 'targetAmount',
  about: 'template.value.about',
  tagline: 'template.value.tagline',
  heroImage: 'template.value.heroImagePath',
  heroVideo: 'template.value.heroVideoUrl',
  mainImage: 'mainImagePath',
  primaryColor: 'template.value.style.primaryColor',
  secondaryColor: 'template.value.style.secondaryColor',
  allowTimeAndPlace: 'template.value.timeAndPlace.enabled',
  allowNewOrg: 'template.value.custom-clone.newOrg.enabled',
  newOrg: 'template.value.custom-clone.newOrg.organization',
  newOrgId: 'template.value.custom-clone.newOrg.organizationId',
  allowNewUrlPath: 'template.value.custom-clone.newUrl.enabled',
  newUrlPath: 'template.value.custom-clone.newUrl.path',
  timeAndPlaceHeader: 'timeAndPlace',
  dateAndTime: 'dateAndTime',
  timeAndPlace: 'timeAndPlace',
  date: 'template.value.timeAndPlace.date',
  time: 'template.value.timeAndPlace.time',
  endDate: 'template.value.timeAndPlace.endDate',
  endTime: 'template.value.timeAndPlace.endTime',
  timeExtraDetails: 'template.value.timeAndPlace.text',
  location: 'template.value.timeAndPlace.location',
  timeDetails: 'timeAndPlace.time.text',
  place: 'timeAndPlace.place.text',
  generalSettings: 'generalSettings',

  // Fundraising
  fundraisersEnable: 'fundraisers.enabled',
  fundraisersAllowSelfSignUp: 'fundraisers.allowSelfSignUp',
  fundraisersDefaultTargetAmount: 'fundraisers.defaultTargetAmount',
  fundraisersDefaultText: 'template.value.fundraisers.label',
  teamsEnable: 'teams.enabled',
  teamsAllowSelfSignUp: 'teams.allowSelfSignUp',
  teamsUniqueNames: 'teams.uniqueNameRequired',
  teamsDefaultTargetAmount: 'teams.defaultTargetAmount',
  teamsDefaultText: 'template.value.teams.label',
  registrationPageText: 'template.value.registrationPageText',
  registrationQuestions: `template.value.formBuilder.${customFieldCategory.FUNDRAISING}`,
  allowCollections: 'collections.enabled',
  collectionsGeneralName: 'template.value.collectionSetup.generalName',
  allowCollectionDonations: 'collections.donations',
  allowCollectionSales: 'collections.sales',
  joiningExperience: 'template.value.collectionSetup.joiningExperience',
  isCollectionRequired: 'template.value.collectionSetup.isRequired',
  disableCollectionsPublicPages:
    'template.value.collectionSetup.disablePublicPages',
  enableCollectionPageManager:
    'template.value.collectionSetup.enablePageManager',
  newPageButtonText: 'template.value.customText.joinButtonText',
  allowFundraisingTimeAndPlace: 'template.value.customSetup.allowFundraisingTimeAndPlace',

  // mvp fundraiser
  mvpFundraisersDefaultPath: 'template.page.content.eyes-only.mvp',
  mvpFundraisersDefaultMessagePath:
    'template.page.content.eyes-only.mvp.default-message',
  mvpFundraisersSettings: 'template.value.mvpFundraisers',
  mvpFundraisersEnable: 'template.value.mvpFundraisers.enabled',
  mvpFundraisersTarget: 'template.value.mvpFundraisers.target',
  mvpFundraisersTitle: 'template.value.mvpFundraisers.title',
  mvpFundraisersTitleSuccess: 'template.value.mvpFundraisers.titleSuccess',
  mvpFundraisersMessage: 'template.value.mvpFundraisers.message',
  mvpFundraisersMessageSuccess: 'template.value.mvpFundraisers.messageSuccess',
  mvpFundraisersImage: 'template.value.mvpFundraisers.image',
  mvpFundraisersImageSuccess: 'template.value.mvpFundraisers.imageSuccess',
  mvpFundraisersButton: 'template.value.mvpFundraisers.button',
  // special effects
  specialEffectsConfettiEnabled: 'template.value.specialEffects.confetti.enabled',
  // invite friends
  inviteFriendsDefaultPath: 'template.page.content.eyes-only.invite-friends',
  inviteFriendsEnable: 'template.value.inviteFriends.enabled',
  inviteFriendsCustomText: 'template.value.inviteFriends.customText',
  inviteFriendsCustomDescription: 'template.value.inviteFriends.customDescription',
  inviteFriendsCustomMessage: 'template.value.inviteFriends.customMessage',
  inviteFriendsCustomImage: 'template.value.inviteFriends.customImage',
  // fundraisers resources
  fundraisersResourcesPageEnabled:
    'template.value.fundraisersResourcesPage.enabled',
  fundraisersResourcesPageButtonText:
    'template.value.customText.pages.fundraisersResourcesPage.buttonText',
  fundraisersResourcesPage: 'template.value.fundraisersResourcesPage',
  fundraisersResourcesItems: 'template.value.fundraisersResourcesPage.items',

  //Donation
  nameForDonors: 'template.value.donationSetup.label',
  thankYouMediaType: 'template.value.donationSetup.selectedMediaType',
  thankYouVideo: 'template.value.donationSetup.thankYouVideoUrl',
  thankYouImage: 'template.value.donationSetup.thankYouImageUrl',
  defaultAmounts: 'template.value.donationSetup.defaultAmounts',
  includeFee: 'donationSetup.allowCoverFee',
  allowRecurringGiving: 'donationSetup.allowRecurringGiving',
  thankYouMessage: 'template.value.donationSetup.thankYouMessage',
  donatinPageText: 'template.value.donationSetup.donationPageText',
  donatinPageTitle: 'template.value.customText.donate.header',
  donationQuestions: `template.value.formBuilder.${customFieldCategory.DONATION}`,
  allowedDonationTypes: 'template.value.donationSetup.allowedDonationTypes',
  disableDonationRecurringGiving:
    'template.value.donationSetup.disableRecurringGiving',
  disableDonationOnceOffGiving:
    'template.value.donationSetup.disableOnceOffGiving',
  donationRecurringLimit: 'template.value.donationSetup.donationRecurringLimit',
  donationButtonText: 'template.value.customText.donateButtonText',
  donationReceiptImage: 'template.value.donationReceipt.bannerImageUrl',
  donationReceiptMessage: 'template.value.donationReceipt.thankYouMessage',

  // Advanced
  defaultFundraiserAbout: 'template.value.defaultFundraiserAbout',
  defaultTeamAbout: 'template.value.defaultTeamAbout',
  defaultCollectionAbout: 'template.value.defaultCollectionAbout',
  allowMicroblogs: 'microblogs.enabled',
  allowAchievements: 'achievements.enabled',
  webTracking: 'template.value.webTracking',
  salutationEnabled: 'template.value.customSetup.showSalutation',

  // Ticketing
  ticketIssuingStyle: 'ticketing.ticketIssuingStyle',
  allowTicketing: 'ticketing.enabled',
  allowTicketInstallments: 'ticketing.installments.enabled',
  ticketInstallmentFrequency: 'ticketing.installments.frequency',
  ticketInstallmentNewSalesUntil:
    'template.value.ticketingSetup.instalmentsNewSalesUntil',
  installmentCountOptions: 'ticketing.installments.count',
  simpleTicketingEnabled:
    'template.value.ticketingSetup.simpleTicketingEnabled',
  purchaseTicketUrl: 'template.value.ticketingSetup.purchaseTicketUrl',
  tickets: 'tickets',
  ticketingInvoiceQuestions: `template.value.formBuilder.${customFieldCategory.SALES}`,
  ticketsTitleText: 'template.value.customText.purchaseTicketWizardTitle',
  ticketingIntroductoryText: 'template.value.ticketingSetup.introductoryText',
  ticketingThankYou: 'template.value.ticketingSetup.thankYou',
  ticketsInformation: 'ticketsInformation',
  ticketsButtonText: 'template.value.customText.ticketButtonText',
  allowTeamStep: 'template.value.ticketingSetup.allowTeamStep',
  customTextTicketsTabName: 'template.value.ticketingSetup.customText.tabs.tickets.key',
  customTextTicketsTabTitle: 'template.value.ticketingSetup.customText.tabs.tickets.label',
  customTextDetailsTabName: 'template.value.ticketingSetup.customText.tabs.details.key',
  customTextDetailsTabTitle: 'template.value.ticketingSetup.customText.tabs.details.title',
  customTextDetailsTabTicketNoun: 'template.value.ticketingSetup.customText.tabs.details.ticketLabel',
  customTextDetailsTabCopyFrom: 'template.value.ticketingSetup.customText.tabs.details.copyFrom',
  customTextDetailsTabTicketEmailSubText: 'template.value.ticketingSetup.customText.tabs.details.ticketEmailSubtext',
  customTextFundraisingTabName: 'template.value.ticketingSetup.customText.tabs.fundraise.key',
  customTextWaiversTabName: 'template.value.ticketingSetup.customText.tabs.waivers.key',
  customTextMerchandiseTabName: 'template.value.ticketingSetup.customText.tabs.merchandise.key',
  customTextPaymentTabName: 'template.value.ticketingSetup.customText.tabs.payment.key',
  customTextPaymentTabTitle: 'template.value.ticketingSetup.customText.tabs.payment.summaryTitle',
  customTextPaymentTabPaymentTitle: 'template.value.ticketingSetup.customText.tabs.payment.paymentTitle',
  customTextPaymentTabPaymentTableProcessingFeeTicketsLabel: 'template.value.ticketingSetup.customText.tabs.payment.tableProcessingFeeTickets',
  purchaseFundraisingEnabled:
    'template.value.ticketingSetup.purchaseFundraisingEnabled',
  fundraisingTargetAmountEnabled: 'template.value.ticketingSetup.fundraisingTargetAmountEnabled',
  fundraisingTargetAmountAllowOther: 'template.value.ticketingSetup.fundraisingTargetAmountAllowOther',
  fundraisingTargetAmountOptions: 'template.value.ticketingSetup.fundraiserTargetAmountOptions',
  fitnessTargetAmountEnabled: 'template.value.ticketingSetup.fundraisingFitnessTargetsEnabled',
  fitnessTargetAmountAllowOther: 'template.value.ticketingSetup.fundraisingFitnessTargetAllowOther',
  fitnessDistanceTargetAmountEnabled: 'template.value.ticketingSetup.showFundraiserFitnessDistanceTargetAmount',
  fitnessDistanceTargetAmountOptions: 'template.value.ticketingSetup.fundraiserFitnessDistanceOptions',
  fitnessTimeTargetAmountEnabled: 'template.value.ticketingSetup.showFundraiserFitnessTimeTargetAmount',
  fitnessTimeTargetAmountOptions: 'template.value.ticketingSetup.fundraiserFitnessTimeOptions',
  fitnessStepsTargetAmountEnabled: 'template.value.ticketingSetup.fundraisingFitnessStepsTargetAllowOther',
  fitnessStepsTargetAmountOptions: 'template.value.ticketingSetup.fundraiserFitnessStepsOptions',
  purchaseFundraisingIsMandatory: 'ticketing.purchaseFundraisingIsMandatory',
  purchaseFundraisingStyle: 'ticketing.purchaseFundraisingStyle',
  allowedSaleDonationTypes:
    'template.value.ticketingSetup.allowedSaleDonationTypes',
  disableOneClickSaleDonations:
    'template.value.ticketingSetup.disableOneClickDonations',
  ticketingDefaultDonationAmounts: 'template.value.customSetup.ticketingDefaultDonationAmounts',
  ticketingDefaultSelectedDonationAmount: 'template.value.customSetup.ticketingDefaultSelectedDonationAmount',
  enableSaleDonations: 'template.value.ticketingSetup.enableSaleDonations',
  disableSaleRecurringGiving:
    'template.value.ticketingSetup.disableRecurringGiving',
  disableSaleOnceOffGiving:
    'template.value.ticketingSetup.disableOnceOffGiving',
  saleRecurringLimit: 'template.value.ticketingSetup.saleRecurringLimit',
  paymentFeeCalculation: 'template.value.ticketingSetup.paymentFeeCalculation',  
  paymentPlatforms: 'paymentPlatforms',
  // single user registration
  singleUserRegistrationMode:
    'template.value.ticketingSetup.singleUserRegistrationMode',
  oneTicketTypePerSale: 'template.value.ticketingSetup.oneTicketTypePerSale',
  maxTicketsToSale: 'template.value.customSetup.maxTicketsToSale',

  // Terms, Conditions & Waivers
  termsAndConditionsCheckbox: 'termsAndConditionsCheckbox',
  termsAndConditionSetupTab: 'termsAndConditionSetupTab',
  termsAndConditionSetup: 'template.value.termsAndConditionSetup',
  allowtermsAndConditionSetupTab:
    'template.value.termsAndConditionSetup.allowTermsAndConditionSetup',
  allowTermsAndConditions:
    'template.value.termsAndConditionSetup.allowTermsAndConditions',
  allowWaivers: 'template.value.termsAndConditionSetup.allowWaivers',

  // Merchandise
  merchandiseTab: 'merchandiseTab',
  allowMerchandise: 'merchandise.enabled',
  merchandise: 'merchandising',
  availableMerchandise: 'availableMerchandise',

  // Discounts
  allowCoupons: 'discounts.saleCoupons',
  coupons: 'coupons',

  // Custom
  customURLStyle: 'customDomain.domainStyle',
  customUrlDomain: 'customDomain.domain',
  customUrlPath: 'urlPath',
  customUrlCheckbox: 'customUrl-checkbox',
  customUrlCheckboxPath: 'template.value.customUrl.enabled',
  allowCustomUrlCheckbox: 'template.value.customUrl.isAllowed',

  // Qr codes
  qrCodesHeader: 'qrCodesHeader',
  qrCodesContent: 'qrCodesContent',
  qrCodesCheckbox: 'qrCodesCheckbox',
  qrCodesButtons: 'qrCodesButtons',
  unbrandedQrCode: 'template.value.customSetup.unbrandedQrCode',

  // Fitness
  fitnessEnabled: 'fitnessSetup.enabled',
  fitnessAllowedTypes: 'fitnessSetup.allowedTypes',
  fitnessAllowedTrackedStatistics: 'fitnessSetup.allowedTrackedStatistics',
  fitnessTargets: 'fitnessSetup.targets',
  fitnessValidFrom: 'fitnessSetup.validFrom',
  fitnessValidTo: 'fitnessSetup.validTo',
  fitnessValidDateRule: 'fitnessSetup.validDateRule',
  fitnessThermometersToShow: 'template.value.fitnessSetup.thermometersToShow',
  fitnessLeaderboardsToShow: 'template.value.fitnessSetup.leaderboardsToShow',
  fitnessAllowedPlatforms: 'fitnessSetup.allowedPlatforms',
  allowPageTracking: 'template.value.fitnessSetup.allowPageTracking',

  // Gift Matching
  giftMatching: 'giftMatching',

  //Other
  tags: 'tags',
  linkedCampaignTag: 'template.value.linkedCampaignTag',
  invertPrimaryTextColor: 'template.value.style.invertPrimaryTextColor',
  invertSecondaryTextColor: 'template.value.style.invertSecondaryTextColor',
  forceRedirectTo: 'template.value.forceRedirectTo',
  raisedTotalType: 'generalSettings.raisedTotalType',
  shareWidgetEnabled: 'template.value.customSetup.shareWidget.enabled',
  returnUrl: 'template.value.donationSetup.returnUrl',
  allowPledgePayment: 'template.value.donationSetup.allowPledgePayment',
  displayFullAmountPrecision:
    'template.value.customSetup.displayFullAmountPrecision',
  disableBankAccountPayment:
    'template.value.donationSetup.disableBankAccountPayment',
  shareWidgetTitle: 'template.value.customSetup.shareWidget.title',
  shareWidgetContent: 'template.value.customSetup.shareWidget.content',
  newsletterHidden: 'template.value.customSetup.newsletter.hide',
  newsletterDefaultValue: 'template.value.customSetup.newsletter.defaultValue',
  newsletterPageTypes: 'template.value.customSetup.newsletter.pages',
  donationCustomThankyouText: 'template.value.customText.donate.thankyou-text',
  contentSite: 'template.value.contentSite',
  newsletterCheckboxText: 'template.value.customText.newsletterCheckboxText',
  additionalEventFee: 'additionalEventFee',
  additionalInfoBoxOrganization: 'template.value.customSetup.additionalInfoBoxOrganization',

  //Recurring Custom Fields
  recurringGivingCustomSalutationFieldEnabled:
    'template.value.recurringSetup.customFields.salutation.enabled',
  recurringGivingCustomSalutationFieldRequired:
    'template.value.recurringSetup.customFields.salutation.required',

  recurringGivingCustomAddressFieldEnabled:
    'template.value.recurringSetup.customFields.address.enabled',
  recurringGivingCustomAddressFieldRequired:
    'template.value.recurringSetup.customFields.address.required',

  recurringGivingCustomPhoneFieldEnabled:
    'template.value.recurringSetup.customFields.phoneNumber.enabled',
  recurringGivingCustomPhoneFieldRequired:
    'template.value.recurringSetup.customFields.phoneNumber.required',

  //Custom extra fields
  coordinatorFirstName: 'CoordinatorFirstName',
  coordinatorLastName: 'CoordinatorLastName',
  coordinatorEmail: 'CoordinatorEmail',
  coordinatorPhone: 'CoordinatorPhone',

  //Custom activities
  customActivitiesEnable: 'customActivities.enabled',

  customActivitiesValue1Name: 'customActivities.value1.name',
  customActivitiesValue1CampaignTargetField: 'customActivities.value1.target',
  customActivitiesValue1FundraiserDefaultTargetField:
    'customActivities.value1.fundraiserDefaultTarget',
  customActivitiesValue1TeamDefaultTargetField:
    'customActivities.value1.teamDefaultTarget',
  customActivitiesValue1Unit: 'customActivities.value1.unitName',

  customActivitiesValue1Svg: 'template.value.customActivitiesSetup.value1.svg',
  customActivitiesValue1ThermometerEnabled:
    'template.value.customActivitiesSetup.value1.enableThermometer',
  customActivitiesValue1LeaderBoardEnabled:
    'template.value.customActivitiesSetup.value1.enableLeaderboard',

  saleCustomBehaviourOneTicketTypePerSale:
    'template.value.ticketingSetup.oneTicketTypePerSale',
  saleCustomBehaviourMaxTicketsToSale:
    'template.value.customSetup.maxTicketsToSale'
};

export const templates = {};

templates[campaignTypes.giveNow] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.giveNow)
    .setValue(campaignContract.fundraisersEnable, false)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, false)
    .setValue(campaignContract.teamsEnable, false)
    .setValue(campaignContract.teamsAllowSelfSignUp, false)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.allowMicroblogs, false)
    .setValue(campaignContract.allowAchievements, false)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, false)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.about)
    .hide(campaignContract.allowedSaleDonationTypes)
    .hide(campaignContract.enableSaleDonations)
    .hide(campaignContract.ticketingDefaultDonationAmounts)
    .hide(campaignContract.saleRecurringLimit)
    .hide('recurring-sale-info-recurringAndOnceOff')
    .hide('recurring-sale-info-onlyOnceOff')
    .hide('recurring-sale-info-onlyRecurring')
    .hide(campaignContract.nameForDonors)
    .hide('fundraisingTab')
    .hide('defaultAboutHeader')
    .hide(campaignContract.allowMicroblogs)
    .hide(campaignContract.allowAchievements)
    .hide(campaignContract.tagline)
    .hide('ticketingTab')
    .hide('discountsTab')
    .hide(campaignContract.termsAndConditionSetupTab)
    .hide(campaignContract.allowtermsAndConditionSetupTab)
    .hide(campaignContract.singleUserRegistrationMode)
    .hide(campaignContract.merchandiseTab)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.fitnessEnabled)
    .hide('fitnessTab')
    .hide(campaignContract.heroVideo)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.appeal] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.appeal)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(campaignContract.fundraisersEnable, false)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, false)
    .setValue(campaignContract.teamsEnable, false)
    .setValue(campaignContract.teamsAllowSelfSignUp, false)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.allowMicroblogs, false)
    .setValue(campaignContract.allowAchievements, false)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, true)
    .setValue(campaignContract.enableSaleDonations, true)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.purchaseFundraisingEnabled, false)
    .setValue(
      campaignContract.purchaseFundraisingIsMandatory,
      mandatoryOptions.find((x) => x.value === false).value
    )
    .setValue(
      campaignContract.purchaseFundraisingStyle,
      ticketPurchaseFundraisingStyles.find(
        (x) => x.value === defaultTicketPurchaseFundraisingStyle
      ).value
    )
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.saleRecurringLimit)
    .hide('fundraisingTab')
    .hide('defaultAboutHeader')
    .hide(campaignContract.allowMicroblogs)
    .hide(campaignContract.allowAchievements)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.allowTeamStep)
    .hide(campaignContract.purchaseFundraisingEnabled)
    .hide(campaignContract.purchaseFundraisingIsMandatory)
    .hide(campaignContract.purchaseFundraisingStyle)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.default] = () => {
  return new TemplateOptions()
    .setValue(campaignContract.type, campaignTypes.default)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.teamsEnable, true)
    .setValue(campaignContract.teamsAllowSelfSignUp, true)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.includeFee, true)
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultText
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, true)
    .setValue(campaignContract.allowAchievements, true)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, true)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.purchaseFundraisingEnabled, true)
    .setValue(
      campaignContract.purchaseFundraisingIsMandatory,
      mandatoryOptions.find((x) => x.value === false).value
    )
    .setValue(
      campaignContract.purchaseFundraisingStyle,
      ticketPurchaseFundraisingStyles.find(
        (x) => x.value === defaultTicketPurchaseFundraisingStyle
      ).value
    )
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.event] = () => {
  return (
    new TemplateOptions()
      .setValue(campaignContract.type, campaignTypes.event)
      .setValue(campaignContract.fundraisersEnable, true)
      .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
      .setValue(campaignContract.teamsEnable, true)
      .setValue(campaignContract.teamsAllowSelfSignUp, true)
      .setValue(campaignContract.teamsUniqueNames, false)
      .setValue(campaignContract.includeFee, true)
      .setValue(
        campaignContract.defaultFundraiserAbout,
        individualAboutDefaultText
      )
      .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
      .setValue(
        campaignContract.defaultCollectionAbout,
        collectionAboutDefaultText
      )
      .setValue(campaignContract.allowMicroblogs, true)
      .setValue(campaignContract.allowAchievements, true)
      .setValue(campaignContract.webTracking, defaultWebTrackingElements)
      .setValue(campaignContract.allowTicketing, false)
      .setValue(campaignContract.allowCoupons, false)
      .setValue(campaignContract.allowMerchandise, false)
      .setValue(campaignContract.allowCollections, false)
      .setValue(campaignContract.allowCollectionSales, false)
      .setValue(campaignContract.enableCollectionPageManager, false)
      .setValue(campaignContract.allowCollectionDonations, false)
      .setValue(campaignContract.allowRecurringGiving, true)
      .setValue(campaignContract.fitnessEnabled, false)
      .hide('ticketingTab')
      .hide('discountsTab')
      .hide(campaignContract.termsAndConditionSetupTab)
      .hide(campaignContract.allowtermsAndConditionSetupTab)
      .hide(campaignContract.singleUserRegistrationMode)
      .hide(campaignContract.merchandiseTab)
      .hide(campaignContract.customURLStyle)
      .hide(campaignContract.customUrlDomain)
      .hide(campaignContract.customUrlPath)
      .hide(campaignContract.customUrlCheckboxPath)
      .hide(campaignContract.allowedDonationTypes)
      .hide('recurring-donation-info-recurringAndOnceOff')
      .hide('recurring-donation-info-onlyOnceOff')
      .hide('recurring-donation-info-onlyRecurring')
      .hide('fitnessTab')
      .hide('customActivitiesTab')
      //.hide(campaignContract.heroVideo)
      .hide(campaignContract.qrCodesHeader)
      .hide(campaignContract.qrCodesContent)
      .hide(campaignContract.qrCodesCheckbox)
      .hide(campaignContract.qrCodesButtons)
      .hide(campaignContract.defaultCollectionAbout)
      .hide('dateAndTime')
      .hide(campaignContract.timeExtraDetails)
      .hide(campaignContract.location)
      .hide(campaignContract.timeDetails)
      .hide(campaignContract.place)
      .hide(campaignContract.allowTimeAndPlace)
      .hide(campaignContract.timeAndPlaceHeader)
      .setValue(campaignContract.unbrandedQrCode, false)
  );
};

templates[campaignTypes.bespoke] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.bespoke)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.fundraisersDefaultTargetAmount, 500)
    .setValue(campaignContract.teamsEnable, true)
    .setValue(campaignContract.teamsAllowSelfSignUp, true)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.teamsDefaultTargetAmount, 5000)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultText
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, true)
    .setValue(campaignContract.allowAchievements, true)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, true)
    .setValue(campaignContract.enableSaleDonations, true)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.purchaseFundraisingEnabled, true)
    .setValue(
      campaignContract.purchaseFundraisingIsMandatory,
      mandatoryOptions.find((x) => x.value === false).value
    )
    .setValue(
      campaignContract.purchaseFundraisingStyle,
      ticketPurchaseFundraisingStyles.find(
        (x) => x.value === defaultTicketPurchaseFundraisingStyle
      ).value
    )
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.inMemory] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.specialEffectsConfettiEnabled, false)
    .setValue(campaignContract.type, campaignTypes.inMemory)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.teamsEnable, false)
    .setValue(campaignContract.teamsAllowSelfSignUp, false)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultTextInMemoryCampaign
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, false)
    .setValue(campaignContract.allowAchievements, false)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, false)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide('ticketingTab')
    .hide('discountsTab')
    .hide(campaignContract.termsAndConditionSetupTab)
    .hide(campaignContract.allowtermsAndConditionSetupTab)
    .hide(campaignContract.singleUserRegistrationMode)
    .hide(campaignContract.merchandiseTab)
    .hide(campaignContract.teamsEnable)
    .hide(campaignContract.targetAmount)
    .hide(campaignContract.defaultTeamAbout)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.fitnessEnabled)
    .hide('fitnessTab')
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.celebration] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.celebration)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.teamsEnable, false)
    .setValue(campaignContract.teamsAllowSelfSignUp, false)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultText
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, true)
    .setValue(campaignContract.allowAchievements, true)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, false)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide('ticketingTab')
    .hide('discountsTab')
    .hide(campaignContract.termsAndConditionSetupTab)
    .hide(campaignContract.allowtermsAndConditionSetupTab)
    .hide(campaignContract.singleUserRegistrationMode)
    .hide(campaignContract.merchandiseTab)
    .hide(campaignContract.teamsEnable)
    .hide(campaignContract.targetAmount)
    .hide(campaignContract.defaultTeamAbout)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.challenge] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.challenge)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.fundraisersDefaultTargetAmount, 500)
    .setValue(campaignContract.teamsEnable, true)
    .setValue(campaignContract.teamsAllowSelfSignUp, true)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.teamsDefaultTargetAmount, 5000)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultText
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, true)
    .setValue(campaignContract.allowAchievements, true)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, true)
    .setValue(campaignContract.enableSaleDonations, true)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.purchaseFundraisingEnabled, true)
    .setValue(
      campaignContract.purchaseFundraisingIsMandatory,
      mandatoryOptions.find((x) => x.value === false).value
    )
    .setValue(
      campaignContract.purchaseFundraisingStyle,
      ticketPurchaseFundraisingStyles.find(
        (x) => x.value === defaultTicketPurchaseFundraisingStyle
      ).value
    )
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.cycling] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.cycling)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.fundraisersDefaultTargetAmount, 500)
    .setValue(campaignContract.teamsEnable, true)
    .setValue(campaignContract.teamsAllowSelfSignUp, true)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.teamsDefaultTargetAmount, 5000)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultText
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, true)
    .setValue(campaignContract.allowAchievements, true)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, true)
    .setValue(campaignContract.enableSaleDonations, true)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.purchaseFundraisingEnabled, true)
    .setValue(
      campaignContract.purchaseFundraisingIsMandatory,
      mandatoryOptions.find((x) => x.value === false).value
    )
    .setValue(
      campaignContract.purchaseFundraisingStyle,
      ticketPurchaseFundraisingStyles.find(
        (x) => x.value === defaultTicketPurchaseFundraisingStyle
      ).value
    )
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.running] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.running)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.fundraisersDefaultTargetAmount, 500)
    .setValue(campaignContract.teamsEnable, true)
    .setValue(campaignContract.teamsAllowSelfSignUp, true)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.teamsDefaultTargetAmount, 5000)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultText
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, true)
    .setValue(campaignContract.allowAchievements, true)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, true)
    .setValue(campaignContract.enableSaleDonations, true)
    .setValue(campaignContract.allowCoupons, false)
    .setValue(campaignContract.purchaseFundraisingEnabled, true)
    .setValue(
      campaignContract.purchaseFundraisingIsMandatory,
      mandatoryOptions.find((x) => x.value === false).value
    )
    .setValue(
      campaignContract.purchaseFundraisingStyle,
      ticketPurchaseFundraisingStyles.find(
        (x) => x.value === defaultTicketPurchaseFundraisingStyle
      ).value
    )
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.tagline)
    .hide(campaignContract.customURLStyle)
    .hide(campaignContract.customUrlDomain)
    .hide(campaignContract.customUrlPath)
    .hide(campaignContract.customUrlCheckboxPath)
    .setValue(campaignContract.allowCustomUrlCheckbox, true)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

templates[campaignTypes.custom] = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(campaignContract.type, campaignTypes.custom)
    .setValue(campaignContract.fundraisersEnable, true)
    .setValue(campaignContract.fundraisersAllowSelfSignUp, true)
    .setValue(campaignContract.fundraisersDefaultTargetAmount, 500)
    .setValue(campaignContract.teamsEnable, true)
    .setValue(campaignContract.teamsAllowSelfSignUp, true)
    .setValue(campaignContract.teamsUniqueNames, false)
    .setValue(campaignContract.teamsDefaultTargetAmount, 5000)
    .setValue(campaignContract.includeFee, true)
    .setValue(campaignContract.nameForDonors, 'Donors')
    .setValue(
      campaignContract.defaultFundraiserAbout,
      individualAboutDefaultText
    )
    .setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText)
    .setValue(
      campaignContract.defaultCollectionAbout,
      collectionAboutDefaultText
    )
    .setValue(campaignContract.allowMicroblogs, true)
    .setValue(campaignContract.allowAchievements, true)
    .setValue(campaignContract.webTracking, defaultWebTrackingElements)
    .setValue(campaignContract.allowTicketing, true)
    .setValue(campaignContract.enableSaleDonations, true)
    .setValue(campaignContract.allowCoupons, false)
    .hide(campaignContract.tagline)
    .setValue(
      campaignContract.customURLStyle,
      customUrlStyles.find((x) => x.value === defaultCustomUrlStyle).value
    )
    .setValue(campaignContract.purchaseFundraisingEnabled, true)
    .setValue(
      campaignContract.purchaseFundraisingIsMandatory,
      mandatoryOptions.find((x) => x.value === false).value
    )
    .setValue(
      campaignContract.purchaseFundraisingStyle,
      ticketPurchaseFundraisingStyles.find(
        (x) => x.value === defaultTicketPurchaseFundraisingStyle
      ).value
    )
    .setValue(campaignContract.allowMerchandise, false)
    .setValue(campaignContract.allowCollections, false)
    .setValue(campaignContract.allowCollectionSales, false)
    .setValue(campaignContract.enableCollectionPageManager, false)
    .setValue(campaignContract.allowCollectionDonations, false)
    .setValue(campaignContract.allowRecurringGiving, true)
    .setValue(campaignContract.fitnessEnabled, false)
    .hide(campaignContract.qrCodesHeader)
    .hide(campaignContract.qrCodesContent)
    .hide(campaignContract.customUrlCheckboxPath)
    .hide(campaignContract.qrCodesCheckbox)
    .hide(campaignContract.qrCodesButtons)
    .hide(campaignContract.defaultCollectionAbout)
    .setValue(campaignContract.unbrandedQrCode, false);
};

export default Object.assign(TemplateOptions.prototype, {
  setTeamValue(value) {
    this.setValue(campaignContract.teamsEnable, value);

    if (value) {
      this.setValue(campaignContract.defaultTeamAbout, teamAboutDefaultText);
    } else {
      this.hide(campaignContract.defaultTeamAbout);
    }

    return this;
  },

  setFundraiserValue(value) {
    this.setValue(campaignContract.fundraisersEnable, value);

    if (value) {
      this.setValue(
        campaignContract.defaultFundraiserAbout,
        individualAboutDefaultText
      );
    } else {
      this.hide(campaignContract.defaultTeamAbout);
    }

    return this;
  },

  getWithRule() {
    const isTeamEnabled = this.getValue(campaignContract.teamsEnable);
    if (!isTeamEnabled) {
      this.hide(campaignContract.defaultTeamAbout);
    }

    const isFundraiserEnabled = this.getValue(
      campaignContract.fundraisersEnable
    );
    if (!isFundraiserEnabled) {
      this.hide(campaignContract.defaultFundraiserAbout);
    }

    const isCollectionsEnabled = this.getValue(
      campaignContract.allowCollections
    );
    if (!isCollectionsEnabled) {
      this.hide(campaignContract.defaultCollectionAbout);
    } else {
      this.show(campaignContract.defaultCollectionAbout);
    }

    return this.get();
  }
});
